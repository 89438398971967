import { BehandlerService } from '@/services/behandlerService';

import { patientenUmsaetzeStore } from '@/state/patientenUmsaetzeStore';
import {
  behandlerArrayFormatter,
  behandlerFormatter,
  dateCol,
  euroCol,
  euroFormatterEmptyUndefined,
  euroPercentColPair,
  folgeTermineComparer,
  folgeTermineFormatter,
  folgeTermineRawDateFormatter,
  patientComparer,
  patientDisplayText,
  percentCol,
  txtCol,
  IRoseAGGridColumn,
} from '@rose/common-ui';
import { Units, IPatientenUmsaetzeExtended } from '@rose/types';
import { chain } from 'lodash';

export enum ColumnGroup {
  standard = 'standard',
  umsatzart = 'umsatzart',
  abgerechnet = 'abgerechnet',
  bezahlt = 'bezahlt',
  goz = 'goz',
  bema = 'bema',
  umsatzsteuer = 'umsatzsteuer',
}

const behandlerService = new BehandlerService();
export const patientenUmsaetzeColumnDefs: IRoseAGGridColumn<IPatientenUmsaetzeExtended>[] = [
  {
    headerName: 'Patient',
    headerTooltip: 'Patient',
    sortable: true,
    sort: 'asc',
    pinned: 'left',
    width: 300,
    comparator: patientComparer,
    valueFormatter: params => params && patientDisplayText(params.data),
    cellRendererSelector: (params: any) => {
      if (params.data.isSummary) {
        return { component: 'PatientenUmsaetzeSummary' };
      }
      return { component: 'PatientCell' };
    },
    suppressMovable: true,
    exportWidth: 180,
    columnGroup: ColumnGroup.standard,
  },
  {
    headerName: 'Termin',
    headerTooltip: 'Termin',
    field: 'folgetermine',
    cellClass: 'ag-cell-with-component',
    width: 30,
    sortable: true,
    resizable: true,
    comparator: folgeTermineComparer,
    valueFormatter: folgeTermineFormatter,
    cellRendererSelector: (params: any) => {
      if (params.data?.isSummary) {
        return undefined;
      }
      return { component: 'TermineCell' };
    },
    exportWidth: 80,
    exportUnit: Units.DATUM,
    excelValueFormatter: folgeTermineRawDateFormatter,
    useFormatterForExport: true,
    columnGroup: ColumnGroup.standard,
  },
  dateCol('leistungvon', 'Datum von', ColumnGroup.standard, 100),
  dateCol('leistungbis', 'Datum bis', ColumnGroup.standard, 100),
  // GROUP
  {
    headerName: 'Umsatzarten',
    headerTooltip: 'Leistungen nach Umsatzart und Gebührenordnung',
    children: [
      {
        headerName: 'Umsatz',
        headerTooltip: 'Umsatz, gesamt',
        children: [euroCol('gesamt', 'Gesamt', ColumnGroup.umsatzart, 150)],
      },
      {
        headerName: 'Honorar',
        headerTooltip: 'Honorar-Leistungen',
        columnGroupShow: 'open',
        children: [
          euroCol('honorar', 'Gesamt', ColumnGroup.umsatzart, 80, false),
          euroCol('honorargoz', 'GOZ', ColumnGroup.umsatzart, 80, false),
          euroCol('honorarbema', 'BEMA', ColumnGroup.umsatzart, 80, false),
          euroCol('honorarerstattung', 'Erstattung', ColumnGroup.umsatzart, 80, false),
        ],
      },
      {
        headerName: 'Eigenlabor',
        headerTooltip: 'Eigenlabor-Leistungen',
        columnGroupShow: 'open',
        children: [
          euroCol('eigenlabor', 'Gesamt', ColumnGroup.umsatzart, 80, false),
          euroCol('eigenlaborleistungen', 'Leistungen', ColumnGroup.umsatzart, 100, false),
          euroCol('eigenlabormaterial', 'Material', ColumnGroup.umsatzart, 80, false),
          euroCol('eigenlaborbeb', 'BEB', ColumnGroup.umsatzart, 80, false),
          euroCol('eigenlaborbel', 'BEL', ColumnGroup.umsatzart, 80, false),
        ],
      },
      {
        headerName: 'Fremdlabor',
        headerTooltip: 'Fremdlabor-Leistungen',
        columnGroupShow: 'open',
        children: [
          euroCol('fremdlabor', 'Gesamt', ColumnGroup.umsatzart, 80, false),
          txtCol('fremdlabornamen', 'Labore', ColumnGroup.umsatzart, 80, false),
        ],
      },
      euroCol('material', 'ZA Material', ColumnGroup.umsatzart, 80, false),
      euroCol('huel', 'HU + EL', ColumnGroup.umsatzart, 80, false),
      percentCol('ertragprozentual', 'Ertrag %', ColumnGroup.umsatzart, 80, false, true),
    ],
  },
  // GROUP
  {
    headerName: 'Abgerechnet',
    headerTooltip: 'In Rechnung gestellte Leistungen',
    children: [
      {
        headerName: 'Gesamt',
        headerTooltip: 'Gesamt',
        children: [euroCol('lgesamt', '€', ColumnGroup.abgerechnet, 140)],
      },
      {
        headerName: 'Nicht Abgerechnet, gesamt',
        headerTooltip: 'Nicht abgerechnete Leistungen, gesamt',
        columnGroupShow: 'open',
        children: [
          euroCol('nagesamt', '€', ColumnGroup.abgerechnet, 100, false),
          percentCol('nagesamtanteil', '%', ColumnGroup.abgerechnet, 100, false),
        ],
      },
      {
        headerName: 'Nicht Abgerechnet, BEMA',
        headerTooltip: 'Nicht abgerechnete Leistungen, BEMA',
        columnGroupShow: 'open',
        children: [
          euroCol('nakasse', '€', ColumnGroup.abgerechnet, 100, false),
          percentCol('nakasseanteil', '%', ColumnGroup.abgerechnet, 100, false),
        ],
      },
      {
        headerName: 'Nicht Abgerechnet, GOZ',
        headerTooltip: 'Nicht abgerechnete Leistungen, GOZ',
        columnGroupShow: 'open',
        children: [
          euroCol('naprivat', '€', ColumnGroup.abgerechnet, 100, false),
          percentCol('naprivatanteil', '%', ColumnGroup.abgerechnet, 100, false),
        ],
      },
      {
        headerName: 'Abgerechnet, Gesamt',
        headerTooltip: 'Abgerechnete Leistungen, Gesamt',
        columnGroupShow: 'open',
        children: [
          euroCol('agesamt', '€', ColumnGroup.abgerechnet, 90, false),
          percentCol('agesamtanteil', '%', ColumnGroup.abgerechnet, 90, false),
        ],
      },
      {
        headerName: 'Abgerechnet, BEMA',
        headerTooltip: 'Abgerechnete Leistungen, BEMA',
        columnGroupShow: 'open',
        children: [
          euroCol('akasse', '€', ColumnGroup.abgerechnet, 75, false),
          percentCol('akasseanteil', '%', ColumnGroup.abgerechnet, 75, false),
        ],
      },
      {
        headerName: 'Abgerechnet, GOZ',
        headerTooltip: 'Abgerechnete Leistungen, GOZ',
        columnGroupShow: 'open',
        children: [
          euroCol('aprivat', '€', ColumnGroup.abgerechnet, 75, false),
          percentCol('aprivatanteil', '%', ColumnGroup.abgerechnet, 75, false),
        ],
      },
    ],
  },
  // GROUP
  {
    headerName: 'Bezahlt (Privatliquidation)',
    headerTooltip: 'Bezahlte Leistungen',
    children: [
      euroPercentColPair('renichtbezahlt', 'Nicht bezahlt', ColumnGroup.bezahlt, 100, false, false),
      euroPercentColPair('rebezahlt', 'Bezahlt', ColumnGroup.bezahlt, 70, false, false),
      euroPercentColPair('reerlassen', 'Erlassen', ColumnGroup.bezahlt, 70, false, false),
      txtCol('reanzahl', '# Rg', ColumnGroup.bezahlt, 120),
      txtCol('renummern', 'RgNr', ColumnGroup.bezahlt, 200, false),
    ],
  },
  // GROUP
  {
    headerName: 'Behandler',
    children: [
      {
        headerName: 'Stammbehandler',
        field: 'stammbehandler',
        sortable: true,
        width: 150,
        resizable: true,
        valueFormatter: behandlerFormatter(behandlerService),
      },
      {
        headerName: 'Leistungserbringer',
        field: 'leistungsErbringerVerteilung',
        width: 250,
        cellRendererParams: {
          typ: 'patientenumsaetze',
        },
        cellRenderer: 'LeistungserbringerCell',
        valueFormatter: behandlerArrayFormatter(behandlerService, v => v.behandler),
        resizable: true,
        exportWidth: 400,
      },
      {
        headerName: 'Honorar-Leistungserbringer',
        field: 'gesamt',
        width: 200,
        sortable: true,
        resizable: true,
        noexport: true,
        valueFormatter: euroFormatterEmptyUndefined,
        valueGetter: (p: any) => {
          const leistungsbehandler = patientenUmsaetzeStore.getters.leistungserbringerSelected;
          if (leistungsbehandler && p.data.leistungsErbringerVerteilung) {
            return chain(p.data.leistungsErbringerVerteilung)
              .filter((lev: any) => lev.behandler === leistungsbehandler.id)
              .sumBy((e: any) => e.honorar)
              .value();
          } else {
            return undefined;
          }
        },
        // pinnedRowCellRenderer: (p: any) => {
        //   if (p.data.honorarLeistungserbringer) {
        //     return '<span>' + `${Number(p.data.honorarLeistungserbringer).toFixed(1)}` + '€ </span>';
        //   }
        //   return '<span></span>';
        // },
      },
    ],
  },
  // GROUP
  {
    headerName: 'GOZ',
    headerTooltip: 'GOZ Leistungen nach Abrechnungsart',
    children: [
      euroCol('leistungszurechnung.GOZGesamt', 'Gesamt GOZ', ColumnGroup.goz, 100, true),
      euroCol('leistungszurechnung.GOZA', 'A. Allgemeine zahnärztliche Leistungen', ColumnGroup.goz, 80, false),
      euroCol('leistungszurechnung.GOZB', 'B. Prophylaktische Leistungen', ColumnGroup.goz, 80, false),
      euroCol('leistungszurechnung.GOZC', 'C. Konservierende Leistungen', ColumnGroup.goz, 80, false),
      euroCol('leistungszurechnung.GOZD', 'D. Chirurgische Leistungen', ColumnGroup.goz, 80, false),
      euroCol(
        'leistungszurechnung.GOZE',
        'E. Leistungen bei Erkrankungen der Mundschleimhaut und des Parodontiums',
        ColumnGroup.goz,
        80,
        false,
      ),
      euroCol('leistungszurechnung.GOZF', 'F. Prothetische Leistungen', ColumnGroup.goz, 80, false),
      euroCol('leistungszurechnung.GOZG', 'G. Kieferorthopädische Leistungen ', ColumnGroup.goz, 80, false),
      euroCol(
        'leistungszurechnung.GOZH',
        'H. Eingliederung von Aufbißbehelfen und Schienen',
        ColumnGroup.goz,
        80,
        false,
      ),
      euroCol(
        'leistungszurechnung.GOZJ',
        'J. Funktionsanalytische und funktionstherapeutische Leistungen',
        ColumnGroup.goz,
        80,
        false,
      ),
      euroCol('leistungszurechnung.GOZK', 'K. Implantologische Leistungen', ColumnGroup.goz, 80, false),
      euroCol('leistungszurechnung.GOZL', 'L. Zuschläge', ColumnGroup.goz, 80, false),
      euroCol('leistungszurechnung.GOZGAE', 'Ä. Leistungen nach GOÄ', ColumnGroup.goz, 80, false),
      euroCol('leistungszurechnung.GOZV', 'V. Verlangensleistungen', ColumnGroup.goz, 80, false),
      euroCol('leistungszurechnung.GOZGU', 'Ohne Zurechnung', ColumnGroup.goz, 80, false),
    ],
  },
  // GROUP
  {
    headerName: 'BEMA',
    headerTooltip: 'BEMA Leistungen nach Abrechnungsart',
    children: [
      euroCol('leistungszurechnung.BEMAGesamt', 'Gesamt BEMA', ColumnGroup.bema, 120, true),
      euroCol(
        'leistungszurechnung.BEMA1',
        'Teil 1 - Konservierende und chirurgische Leistungen und Röntgenleistungen',
        ColumnGroup.bema,
        80,
        false,
      ),
      euroCol(
        'leistungszurechnung.BEMA2',
        'Teil 2 - Kieferbruch, Kiefergelenkserkrankungen',
        ColumnGroup.bema,
        80,
        false,
      ),
      euroCol('leistungszurechnung.BEMA3', 'Teil 3 - Kieferorthopädische Behandlung', ColumnGroup.bema, 80, false),
      euroCol(
        'leistungszurechnung.BEMA4',
        'Teil 4 - Systematische Behandlung von Parodontopathien',
        ColumnGroup.bema,
        80,
        false,
      ),
      euroCol(
        'leistungszurechnung.BEMA5',
        'Teil 5 - Versorgung mit Zahnersatz und Zahnkronen',
        ColumnGroup.bema,
        80,
        false,
      ),
      euroCol('leistungszurechnung.BEMABAE', 'Ä. Leistungen nach GOÄ', ColumnGroup.bema, 80, false),
      euroCol('leistungszurechnung.BEMABU', 'Ohne Zurechnung', ColumnGroup.bema, 80, false),
    ],
  },
  // GROUP
  {
    headerName: 'MwSt.',
    // headerTooltip: 'BEMA Leistungen nach Abrechnungsart',
    children: [
      euroCol('gesamtnetto', 'GU netto', ColumnGroup.umsatzsteuer),
      euroCol('gesamtmwst', 'GU MwSt', ColumnGroup.umsatzsteuer),
      euroCol('gesamt', 'GU brutto', ColumnGroup.umsatzsteuer, 100),
      euroCol('honorarnetto', 'HU netto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('honorarmwst', 'HU MwSt', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('honorar', 'HU brutto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('materialnetto', 'Mat. netto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('materialmwst', 'Mat. MwSt', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('material', 'Mat. brutto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlabornetto', 'EL netto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlabormwst', 'EL MwSt', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlabor', 'EL brutto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlabormaterialnetto', 'EL Mat. netto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlabormaterialmwst', 'EL Mat. MwSt', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlabormaterial', 'EL Mat. brutto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('fremdlabornetto', 'FL netto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlaborleistungennetto', 'EL Leist. netto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlaborleistungenmwst', 'EL Leist. MwSt', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('eigenlaborleistungen', 'EL Leist. brutto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('fremdlabornetto', 'FL netto', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('fremdlabormwst', 'FL MwSt', ColumnGroup.umsatzsteuer, 80, false),
      euroCol('fremdlabor', 'FL brutto', ColumnGroup.umsatzsteuer, 80, false),
    ],
  },
];
